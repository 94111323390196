import { getCurrentHub } from '@sentry/nextjs';
import Icon from '../Icon';

export interface ProsAndConsProps {
  pros: string[];
  cons: string[];
}

export default function ProsAndCons({ pros, cons }: ProsAndConsProps) {
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const prosAndConsSpan = currentSpan && currentSpan.startChild({ op: 'prosAndCons' });

  return (
    <div className="pros-cons-section mb-6 flex flex-col gap-8">
      {!!pros?.length && (
        <div className="pros-cons w-full rounded border border-solid border-mglGray200">
          <div className="pros-cons-div-title bg-mglGray200 px-6 py-3">
            <h3 className="text-[1.5rem] font-medium leading-none">Prós</h3>
          </div>
          <ul className="list-none p-4">
            {pros.map(pro => (
              <li className="flex items-center gap-3" key={pro}>
                <span className="h-[18px] w-[18px]">
                  <Icon icon="mdi:arrow-right" color="#00bfa5" />
                </span>
                <span className="px-0 py-2 text-lg leading-none text-[#2B2B2B]">{pro}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {!!cons?.length && (
        <div className="pros-cons w-full rounded border border-solid border-mglGray200">
          <div className="pros-cons-div-title bg-mglGray200 px-6 py-3">
            <h3 className="text-[1.5rem] font-medium leading-none">Contras</h3>
          </div>
          <ul className="list-none p-4">
            {cons.map(con => (
              <li className="flex items-center gap-3" key={con}>
                <span className="h-[18px] w-[18px]">
                  <Icon icon="mdi:arrow-right" color="#F63D6A" />
                </span>

                <span className="px-0 py-2 text-lg leading-none text-[#2B2B2B]">{con}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {prosAndConsSpan && prosAndConsSpan.finish()}
    </div>
  );
}
