import { getCurrentHub } from '@sentry/nextjs';

export interface ContentBlockquoteProps {
  text: string | string[];
  author?: string;
}

export default function ContentBlockquote({ text, author = '' }: ContentBlockquoteProps) {
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const contentBlockquoteSpan = currentSpan && currentSpan.startChild({ op: 'contentBlockquote' });

  return (
    <blockquote className="blockquote">
      <p>{text || text[0]}</p>
      {!!author && <small>— {author}</small>}
      {contentBlockquoteSpan && contentBlockquoteSpan.finish()}
    </blockquote>
  );
}
