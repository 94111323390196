/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ImgHTMLAttributes, useRef } from 'react';
import { getCurrentHub } from '@sentry/nextjs';
import ContentComposer, { ComponentProps } from '../../ContentComposer';
import * as S from './styles';
import { srcSetDictionary } from '..';
import { editorialCategoryType } from '@/lib/sponsors';

export interface CustomImageComponent extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  width?: number;
  height?: number;
  alt?: string;
  figcaption?: string | string[] | ComponentProps[];
  srcset?: { [key: number]: string };
  editorialCategory?: editorialCategoryType;
  isHeader?: 'internal' | 'full';
}

export default function HeaderImageComponent({
  src,
  alt,
  figcaption = undefined,
  srcset = undefined,
  width = undefined,
  height = undefined,
  editorialCategory = undefined,
  isHeader = undefined,
}: CustomImageComponent) {
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const imageComponentSpan = currentSpan && currentSpan.startChild({ op: 'imageComponent', description: src });
  const headerProps = {};

  if (isHeader) Object.assign(headerProps, { isHeader });

  const image = useRef<HTMLImageElement>();

  return (
    <S.ContainerImage style={{ width: width || '100%', height: height || '100%' }} editorialCategory={!!editorialCategory} {...headerProps}>
      <img
        src={src}
        ref={image}
        srcSet={`${srcset && typeof srcset != null ? Object.keys(srcset).map(e => `${srcset[e]} ${srcSetDictionary[e]}w`) : ''}`}
        sizes="(max-width: 992px) 100vw, 60vw"
        alt={alt}
        width={width && width}
        height={height && height}
        decoding="async"
        fetchPriority="high"
      />
      {figcaption && (
        <S.CaptionImage>
          {figcaption && figcaption !== undefined && figcaption.length > 0 && typeof figcaption === 'string'
            ? figcaption
            : Array.isArray(figcaption) &&
              figcaption?.map((figcapitem, id) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${typeof figcapitem === 'string' ? figcapitem : figcapitem.valor}-${id}`}>
                  {figcapitem && typeof figcapitem === 'string' && figcapitem?.length > 0
                    ? figcapitem
                    : typeof figcapitem === 'object' && (
                        <ContentComposer
                          tipo={figcapitem?.tipo}
                          url={figcapitem.url}
                          as={figcapitem?.tag}
                          valor={figcapitem?.valor}
                          target={figcapitem.target}
                        />
                      )}
                </React.Fragment>
              ))}
          {imageComponentSpan && imageComponentSpan.finish()}
        </S.CaptionImage>
      )}
    </S.ContainerImage>
  );
}
