import LazyLoad from 'react-lazyload';
import Script from 'next/script';
import ContentComposer from '../../Internals/ContentComposer';

export interface SocialMediaEmbedProps {
  id: string;
  type: string;
  hideDataConversation?: boolean;
  category?: string;
  author?: string;
}

export default function SocialMediaEmbed({ id, type, hideDataConversation, category, author }: SocialMediaEmbedProps) {
  const AutoResize = () => (
    <Script id="autoResize" type="text/javascript">
      {`
      const target = document.getElementById('content-news');
      const config = { childList: true, subtree: true };
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === "childList" && mutation.addedNodes?.[0]?.childNodes?.[0]) {
            const iframeId = mutation.addedNodes[0].childNodes[0].id;
            autoResize(iframeId);
          }
        }
      };

      const observer = new MutationObserver(callback);
      observer.observe(target, config);

      async function autoResize(iframeId) {
        let newHeight;
        let counter = 0;
        const selectedIframe = document?.getElementById(iframeId);

        const refreshIframe = setInterval(() => {
          counter++
          if (selectedIframe) {
          newHeight = selectedIframe.contentWindow?.document?.body.scrollHeight + 40 > 360 ? selectedIframe.contentWindow.document.body.scrollHeight + 40 : selectedIframe.height;
          if (selectedIframe.height !== newHeight) selectedIframe.height = newHeight + "px";
          }
          counter === 24 && clearInterval(refreshIframe);
        }, 1000)
      }
`}
    </Script>
  );

  const getAuthor = () => author || '_';

  const getEmbed = () => {
    switch (type) {
      case 'instagram':
        return `<iframe id="${type}-${id}" loading="lazy" width="640" height="360" srcdoc='<blockquote class="instagram-media" data-instgrm-captioned data-instgrm-permalink="https://www.instagram.com/${
          category || 'p'
        }/${id}/?utm_source=ig_embed&amp;utm_campaign=loading" data-instgrm-version="14" style=" background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);"></blockquote> <script async src="//www.instagram.com/embed.js"></script>'></iframe>`;
      case 'twitter':
        return `<iframe id="${type}-${id}" loading="lazy" width="640" height="360" srcdoc='<blockquote class="twitter-tweet tw-align-center" ${
          hideDataConversation ? `data-conversation="none" ` : ``
        }><a href="https://twitter.com/_/status/${id}"></a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'>
              </iframe>
              `;
      case 'reddit':
        return `<iframe id="${type}-${id}" loading="lazy" srcdoc='<blockquote class="reddit-embed-bq" style="height:316px" data-embed-height="316">      <a href="https://www.reddit.com/r/${id}/"></a> </blockquote><script async="" src="https://embed.reddit.com/widgets.js" charset="UTF-8"></script>' sandbox="allow-scripts allow-same-origin allow-popups" height="527" width="640" scrolling="yes"></iframe>`;
      case 'tiktok':
        return `<iframe id="${type}-${id}" loading="lazy" width="640" height="360" srcdoc='<blockquote class="tiktok-embed" data-video-id="${id}" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank"></a> <a target="_blank"></a> <a></a> <a></a> </section> </blockquote> <script async src="https://www.tiktok.com/embed.js"></script>'>
              </iframe>`;
      case 'vimeo':
        return `<iframe id="${type}-${id}" loading="lazy" title="vimeo-player" src="https://player.vimeo.com/video/${id}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
      case 'telegram':
        return `<iframe id="${type}-${id}" loading="lazy" width="640" height="360" srcdoc='<div><script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-post="${id}" data-width="100%"></script></div>'></iframe>`;
      case 'facebook-messenger':
        return `<iframe id="${type}-${id}" loading="lazy" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F_%2Fvideos%2F${id}%2F&width=500&show_text=false&height=626&appId" width="500" height="626" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>`;
      case 'facebook-post':
        return `<iframe id="${type}-${id}" loading="lazy" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F_%2Fphotos%2F_%2F${id}%2F%3Ftype%3D3&show_text=true&width=500" width="500" height="523" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`;
      case 'linkedin':
        return `<iframe id="${type}-${id}" loading="lazy" src="https://www.linkedin.com/embed/feed/update/urn:li:share:${id}" height="841" width="504" frameborder="0" allowfullscreen="" title="Publicação incorporada"></iframe>`;
      case 'spotify-track':
        return `<iframe id="${type}-${id}" loading="lazy" style="border-radius:12px" src="https://open.spotify.com/embed/track/${id}?utm_source=generator" width="300" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`;
      case 'spotify-playlist':
        return `<iframe id="${type}-${id}" loading="lazy" style="border-radius:12px" src="https://open.spotify.com/embed/playlist/${id}?utm_source=generator" width="300" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`;
      case 'spotify-podcast':
        return `<iframe id="${type}-${id}" loading="lazy" style="border-radius:12px" src="https://open.spotify.com/embed/episode/${id}?utm_source=generator" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`;
      case 'scribd':
        return `<iframe id="${type}-${id}" loading="lazy" class="scribd_iframe_embed" src="https://www.scribd.com/embeds/${id}/content?start_page=1&view_mode=scroll&access_key=key-GmUWwEZXun6XOYmbQMHM" tabindex="0" data-auto-height="true" data-aspect-ratio="0.7080062794348508" scrolling="no" width="100%" height="600" frameborder="0"></iframe>`;
      case 'infogram':
        return `<iframe id="${type}-${id}" loading="lazy" src="https://e.infogram.com/${id}?src=embed" width="100%" height="300" scrolling="yes" frameborder="0" style="border:none;" allowfullscreen="allowfullscreen"></iframe>`;
      case 'threads':
        return `<iframe id="${type}-${id}" loading="lazy" width="640" height="536" srcdoc='<blockquote class="text-post-media" data-text-post-permalink="https://www.threads.net/${getAuthor()}/post/${id}" data-text-post-version="0"></blockquote><script async="" src="https://www.threads.net/embed.js"></script>'></iframe>`;
      default:
        console.warn('Unexpected embed type: ', type);
        return '';
    }
  };

  return (
    <LazyLoad height={100}>
      <ContentComposer tipo="html" valor={[getEmbed()]} />
      <AutoResize />
    </LazyLoad>
  );
}
