import Head from 'next/head';
import { useRef, useState } from 'react';
import { getCurrentHub } from '@sentry/nextjs';
import HeaderImageComponent from '@/components/Internals/HeaderImage/Image';
import LazyLoad from 'react-lazyload';
import classNames from '@/utils/className';
import { mglGray } from '@/styles/designSystemColors';

export interface IFrameYoutubeProps {
  videoTitle: string;
  videoId: string;
  lazy?: boolean;
  isInternal?: boolean;
  capa?: {
    srcset?: { [key: number]: string };
    dimensao: {
      largura: number;
      altura: number;
    };
    url: string;
  };
  timestamp?: number;
}

export default function IFrameYoutube({ videoTitle, videoId, lazy = true, isInternal = false, capa = null, timestamp = 0 }: IFrameYoutubeProps) {
  const variants = {
    VideoContainer: {
      isInternal: {
        true: 'mb-6',
        false: '',
      },
    },
  } as const;

  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const iframeYoutubeSpan = currentSpan && currentSpan.startChild({ op: 'iframeYoutube', description: videoTitle || videoId });
  const imageRef = useRef<HTMLImageElement>();

  const getLargestImage = (width: number) => {
    if (width < 400) {
      imageRef.current.src = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    }
  };

  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className={classNames(
        "divIframeYt relative mb-10 w-full overflow-hidden after:block after:pt-[56.25%] after:content-['']",
        variants.VideoContainer.isInternal[isInternal.toString()]
      )}>
      {isActive || !lazy ? (
        <iframe
          className="absolute left-0 top-0 h-full w-full"
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}?start=${timestamp}`}
          title={videoTitle}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : (
        <div className="cursor-pointer" id={videoId} data-embed={videoId} onClick={() => setIsActive(true)}>
          {capa ? (
            <div className="absolute left-0 top-0 h-full w-full filter-none">
              <Head>
                <link rel="preload" as="image" href={capa ? capa.url : `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`} />
              </Head>
              <HeaderImageComponent src={capa.url} alt={videoTitle} srcset={capa.srcset} />
            </div>
          ) : (
            <LazyLoad height={1}>
              <img
                className="absolute left-[-1%] top-[-18%] h-auto w-[102%] blur-sm"
                ref={imageRef}
                src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                onLoad={e => {
                  getLargestImage(e.currentTarget.naturalWidth);
                }}
                alt={videoTitle}
              />
            </LazyLoad>
          )}
          <button
            className="absolute left-[50%] top-[50%] z-[1] h-12 w-[68px] cursor-pointer border-none bg-transparent [transform:translate3D(-50%,-50%,0)]"
            type="button"
            title="Reproduzir vídeo">
            <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
              <path
                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                fill="#F00"
              />
              <path d="M 45,24 27,14 27,34" fill={mglGray.mglGray50} />
            </svg>
          </button>
          {iframeYoutubeSpan && iframeYoutubeSpan.finish()}
        </div>
      )}
    </div>
  );
}
