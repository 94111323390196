import { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import * as S from './styles';
import 'react-image-gallery/styles/css/image-gallery.css';
import ModalGallery from './ModalGallery';
import { GalleryI } from '../CustomComponentComposer/interfaces';

export default function Gallery({ valor }: Pick<GalleryI, 'valor'>) {
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const items = valor?.map(currentImage => {
    const item = {
      original: currentImage.url,
      originalWidth: currentImage.dimensao.largura,
      originalHeight: currentImage.dimensao.altura,
      originalAlt: currentImage.alt,
      originalTitle: currentImage.titulo,
      thumbnail: currentImage.thumbnail,
      thumbnailAlt: currentImage.legenda,
      loading: 'lazy',
    };
    return item;
  });

  return (
    valor?.length > 0 && (
      <>
        <S.GalleryContainer isOpen={isOpen}>
          {!isOpen ? (
            <>
              <ImageGallery
                startIndex={index}
                items={items}
                useTranslate3D
                lazyLoad
                showThumbnails={false}
                showFullscreenButton={false}
                showPlayButton={false}
                showBullets={false}
                showIndex={!isOpen}
                showNav={!isOpen}
                renderLeftNav={onClick => (
                  <S.GalleryNavButton data-testid="inline-previous" left onClick={onClick}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                      style={{
                        verticalAlign: '-0.125em',
                        transform: 'rotate(180deg)',
                      }}>
                      <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
                    </svg>
                  </S.GalleryNavButton>
                )}
                renderRightNav={onClick => (
                  <S.GalleryNavButton data-testid="inline-next" right onClick={onClick}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                      style={{
                        verticalAlign: '-0.125em',
                        transform: 'rotate(360deg)',
                      }}>
                      <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
                    </svg>
                  </S.GalleryNavButton>
                )}
                onClick={() => setIsOpen(true)}
                onSlide={currentIndex => setIndex(currentIndex)}
              />
              <S.GalleryCaptionContainer>
                <S.GalleryCaptionText>{valor[index].legenda}</S.GalleryCaptionText>
                <S.GalleryCredits>{valor[index].credito}</S.GalleryCredits>
              </S.GalleryCaptionContainer>
            </>
          ) : (
            <S.GalleryPlaceholder />
          )}
        </S.GalleryContainer>
        <ModalGallery valor={valor} index={index} setIndex={setIndex} isOpen={isOpen} setIsOpen={setIsOpen} />
      </>
    )
  );
}
