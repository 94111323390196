import { Dispatch, SetStateAction } from 'react';
import ImageGallery from 'react-image-gallery';
import useOutsideClickRef from '@/hooks/useOutsideClickRef';
import CTLogo from '../../../../assets/svg/ctlogo.svg';
import * as S from '../styles';
import 'react-image-gallery/styles/css/image-gallery.css';
import { GalleryI } from '../../CustomComponentComposer/interfaces';
import classNames from '@/utils/className';
import { mglGray } from '@/styles/designSystemColors';
import Icon from '@/components/Layout/Icon';

interface ModalGalleryProps extends Pick<GalleryI, 'valor'> {
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ModalGallery({ valor, index, setIndex, isOpen, setIsOpen }: ModalGalleryProps) {
  const variants = {
    ModalContainer: {
      isOpen: {
        true: 'opacity-100 z-10 overflow-hidden',
        false: 'opacity-0 z-[-1]',
      },
    },
  } as const;

  const modalRef = useOutsideClickRef({ setIsOpen });

  const items = valor?.map(currentImage => {
    const item = {
      original: currentImage.url,
      originalWidth: currentImage.dimensao.largura,
      originalHeight: currentImage.dimensao.altura,
      originalAlt: currentImage.alt,
      originalTitle: currentImage.titulo,
      thumbnail: currentImage.thumbnail,
      thumbnailAlt: currentImage.legenda,
      loading: 'lazy',
    };
    return item;
  });

  return (
    <section
      className={classNames(
        'fixed left-0 top-0 h-full w-full overflow-auto bg-black/90 transition-all duration-300 ease-ease [&>svg]:cursor-pointer [&>svg]:text-2xl',
        variants.ModalContainer.isOpen[isOpen.toString()]
      )}
      role="dialog"
      aria-modal={isOpen}>
      <div className="absolute right-8 top-[72px] z-[4] cursor-pointer text-4xl tablet:top-8" data-testid="modal-close" onClick={() => setIsOpen(false)}>
        <Icon icon="mdi:close" color={mglGray.mglGray50} />
      </div>
      <div className="mx-0 -mb-16 mt-16 hidden items-center justify-center tablet:flex [@media_only_screen_and_(max-height:750px)]:hidden">
        <CTLogo />
      </div>
      <div className="relative m-auto flex h-full min-h-[336px] w-full max-w-[760px] items-center justify-center rounded bg-transparent">
        <S.GalleryContainer isOpen ref={modalRef}>
          {isOpen && (
            <>
              <ImageGallery
                startIndex={index}
                items={items}
                useTranslate3D
                lazyLoad
                showFullscreenButton={false}
                showPlayButton={false}
                showThumbnails
                showIndex
                renderLeftNav={onClick => (
                  <S.GalleryNavButton left onClick={onClick}>
                    <Icon icon="mdi:chevron-right" style={{ transform: 'rotate(0.5turn)' }} />
                  </S.GalleryNavButton>
                )}
                renderRightNav={onClick => (
                  <S.GalleryNavButton right onClick={onClick}>
                    <Icon icon="mdi:chevron-right" />
                  </S.GalleryNavButton>
                )}
                onSlide={currentIndex => setIndex(currentIndex)}
              />
              <S.GalleryCaptionContainer isOpen>
                <S.GalleryCaptionText>{valor[index].legenda}</S.GalleryCaptionText>
                <S.GalleryCredits>{valor[index].credito}</S.GalleryCredits>
              </S.GalleryCaptionContainer>
            </>
          )}
        </S.GalleryContainer>
      </div>
    </section>
  );
}
